




















import { Observer } from 'mobx-vue'
import { Inject, Prop, Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import ClaimerDetailOwnerViewModel from '../viewmodels/claimer-detail-owner-viewmodel'
import { snackController } from '@/components/snack-bar/snack-bar-controller'

@Observer
@Component({
  components: {}
})
export default class ClosePoolDialog extends Vue {
  @Inject() vm!: ClaimerDetailOwnerViewModel
  @Prop({ default: false }) state

  async save() {
    try {
      this.vm.changeEditDialogLoading(true)
      await this.vm.onClosePool()
      snackController.success('Pool closed successfully')
      this.vm.requestClosePoolDialog(false)
      this.$router.push('/claimer/pools')
    } catch (e) {
      console.log('e', e)
      snackController.error(e.message || e.msg)
    } finally {
      this.vm.changeEditDialogLoading(false)
    }
  }
}
